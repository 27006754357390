import axiosClient from 'Services/axios_client'

const pushSubscribe = (serviceWorkerRegistration) => {
  if (window.vapidPublicKey) {
    const options = {
      userVisibleOnly: true,
      applicationServerKey: window.vapidPublicKey
    }

    serviceWorkerRegistration.pushManager.subscribe(options)
      .then((subscription) => {
        axiosClient.post(
          '/push_subscriptions',
          {
            subscription: subscription.toJSON()
          },
          () => {}
        )
      }, (error) => {
        console.error('[PWA]', 'pushManager.subscribe() failed', error);
      })
  } else {
    console.log('[PWA]', 'Missing vapidPublicKey')
  }
}

if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/service-worker.js', { scope: '/' })
  navigator.serviceWorker.ready
    .then(pushSubscribe)
    .then(() => console.log('[PWA]', 'Service worker registered!'));

  // unsubscribe on logout
  document.addEventListener('DOMContentLoaded', () => {
    if (!window.vapidPublicKey) {
      navigator.serviceWorker.ready
        .then(registration => registration.pushManager.getSubscription())
        .then(subscription => {
          if (subscription) {
            return subscription.unsubscribe()
          }
        })
        .then(() => {
          console.log('[PWA]', 'Successfully unsubscribed from push')
        })
        .catch(error => {
          console.error('[PWA]', 'Something went wrong during unsubsribe from push:', error)
        })
    }
  })
}
